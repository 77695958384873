import React from 'react'
import { Helmet } from 'react-helmet-async'

const HelmetComp = ({ metaTitle, metaDescription, canonicalUrl, ogImage, ogImageAlt, ogType }) => {
  return (
    <Helmet>
        <title> {metaTitle} </title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={canonicalUrl} /> 
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={ogImageAlt} />
        <meta property="og:site_name" content="Truth By Will" />
        <meta property="og:type" content={ogType} />
    </Helmet>
  )
}

export default HelmetComp