import React from 'react'
import { useTranslation } from "react-i18next";
// --custom components--
import HelmetComp from '../components/HelmetComp';
// --css--
import '../styles/about.css'
// --images--
import OgLogo from '../assets/og-images/og-logo.png'

function About() {
  const { t } = useTranslation();

  // --meta tags--
  const metaTitle = "About | Truth By Will"
  const metaDescription = "Our primary aim is to present a call of love that presents humanity opportunity to regain its “natural identity” - lost for many generations - through 'life lessons'."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogType = "website"
  const ogImage = OgLogo;
  const ogImageAlt = "about us"

  return (
    <div id="about" className="about-container">
      <HelmetComp 
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        canonicalUrl={canonicalUrl}
        ogType={ogType}
        ogImage={ogImage}
        ogImageAlt={ogImageAlt}
      />
      <div className="about-content">
        <p className="about-title"> {t('about_us')} </p>
        <p className="about-description">
         {t('about_us_description')} 
        </p>
      </div>  
    </div>
  )
}

export default About