import React, {useState} from 'react'
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
// --css--
import '../styles/burgermenu.css'
// --images--
import YoutubeIcon from '../assets/icon/youtube-white.png'
import FacebookIcon from '../assets/icon/facebook-white.png'
import TiktokIcon from '../assets/icon/tiktok-white.png'
import TelegramIcon from '../assets/icon/telegram-white.png'

import HomeIconDark from '../assets/icon/home.png'
import AboutUsIconDark from '../assets/icon/about-us.png'
import AttendIconDark from '../assets/icon/attend.png'
import BlogIconDark from '../assets/icon/blog.png'

import HomeIconLight from '../assets/icon/home-white.png'
import AboutUsIconLight from '../assets/icon/about-us-white.png'
import AttendIconLight from '../assets/icon/attend-white.png'
import BlogIconLight from '../assets/icon/blog-white.png'

import etFlag from '../assets/image/et-flag.png'
import usFlag from '../assets/image/us-flag.png'

function BurgerMenu() {
  const [activeHomeIcon, setActiveHomeIcon] = useState(false)
  const [activeAboutIcon, setActiveAboutIcon] = useState(false)
  const [activeAttendIcon, setActiveAttendIcon] = useState(false)
  const [activeBlogIcon, setActiveBlogIcon] = useState(false)

  const { t } = useTranslation()
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const notCurrentLanguageCode = currentLanguageCode === 'en' ? etFlag : usFlag;
  
  const [code, setCode] = useState(currentLanguageCode);
  const [changeBtn, setChangeBtn] = useState(notCurrentLanguageCode);

  const changeLang = () => {
    const newCode = code === 'en' ? 'am' : 'en';
    const newBtn = changeBtn === etFlag ? usFlag  : etFlag;
    setCode(newCode);
    setChangeBtn(newBtn);
    i18next.changeLanguage(newCode);
  }
  const langText = changeBtn === etFlag ? "አማርኛ" : "ENGLISH";
  
  return (
    <Menu>
      <div className="nav-links">
          <NavLink 
              className={
                ({isActive}) => 
                  (isActive ? (setActiveHomeIcon(true), "menu-item active" ) : 
                  (setActiveHomeIcon(false), "menu-item"))
              } 
              reloadDocument 
              to={"/"}
          >
            <div className="icon-and-menu">
              <img className="menu-icon" src={activeHomeIcon ? HomeIconDark : HomeIconLight} alt="Home" width={20} height={20} /> {t('home')}
            </div>
          </NavLink>
        
          <NavLink 
              className= {
                ({isActive}) => 
                  (isActive ? (setActiveBlogIcon(true), "menu-item active" ) : 
                  (setActiveBlogIcon(false), "menu-item"))
              } 
              reloadDocument
              to={"/blog"}
          >
            <div className="icon-and-menu">
              <img className="menu-icon" src={activeBlogIcon ? BlogIconDark : BlogIconLight} alt="Blog" width={20} height={20} /> {t('blog')}
            </div>
          </NavLink>

          <NavLink 
              className={
                ({isActive}) => 
                  (isActive ? (setActiveAttendIcon(true), "menu-item active" ) : 
                  (setActiveAttendIcon(false), "menu-item"))
              } 
              reloadDocument
              to={"/class"}
          >
            <div className="icon-and-menu">
              <img className="menu-icon" src={activeAttendIcon ? AttendIconDark : AttendIconLight} alt="class" width={20} height={20} /> {t('class')}
            </div>
          </NavLink>

          <NavLink 
              className={
                ({isActive}) => 
                  (isActive ? (setActiveAboutIcon(true), "menu-item active" ) : 
                  (setActiveAboutIcon(false), "menu-item"))
              } 
              reloadDocument
              to={"/about"}
          >
            <div className="icon-and-menu">
              <img className="menu-icon" src={activeAboutIcon ? AboutUsIconDark : AboutUsIconLight } alt="About" width={20} height={20} /> {t('about')}
            </div>
          </NavLink> 

        </div>

        <div className="menu--social-medias-cont">

          <div className="burger-lang-container">
            <span className="burger-lang-label">{t('lang')} </span> 
            <div 
              onClick={changeLang} 
              className="burger-lang-btn"
            > 
              <img width="20" height="10" className="flag burger-flag" src={changeBtn} alt="flag" /> <span className="flag-lang"> {langText} </span>
            </div>
          </div>

          <hr className="menu--hr" />

          <div className="menu--social-medias">
            <a 
              target="_blank" 
              href="https://www.youtube.com/@truthByWill"
              rel="noopener noreferrer"
            >
              <img className="menu--social-icon social--icon" src={YoutubeIcon} alt="youtube" width={25} height={25}/>
            </a>

            <a 
              target="_blank" 
              href="https://www.facebook.com/truthByWill"
              rel="noopener noreferrer"
            >
              <img className="menu--social-icon social--icon" src={FacebookIcon} alt="facebook" width={25} height={25}/>
            </a>

            <a 
              target="_blank" 
              href="https://www.tiktok.com/@truth_bywill"
              rel="noopener noreferrer"
            >
              <img className="menu--social-icon social--icon" src={TiktokIcon} alt="tiktok" width={25} height={25}/>
            </a>

            <a 
              target="_blank" 
              href="https://t.me/truthByWill_yt"
              rel="noopener noreferrer"
            >
              <img className="menu--social-icon social--icon" src={TelegramIcon} alt="telegram" width={25} height={25}/>
            </a>
        </div>
      </div>
    </Menu>
  )
}

export default BurgerMenu