import React, {useState} from 'react'
import { Blurhash } from 'react-blurhash';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
// --components--
import HelmetComp from '../components/HelmetComp';
// --css--
import '../styles/blog.css'
// --images--
import BlogOgImage from '../assets/image/blog.png'

function Blog() {      
  const { t } = useTranslation();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  }

  // --meta tags--
  const metaTitle = "Blog | Truth By Will"
  const metaDescription = "Short articles related with life lesson."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogType = "article"
  const ogImage = BlogOgImage;
  const ogImageAlt = "Blog "
  
  const blogs = t('blog_datas', {returnObjects: true})

  const blogEl = blogs.map(blog => {
    return (
      <div key={blog.blog_title} className="blog-content">
          <NavLink to={blog.path} className='blog-navlink'>

          <div className="blog-blurhash">
            <div className="blurhash-cont" style={{display: isImageLoaded === false ? "inline" : "none"}}>
              <Blurhash 
                hash={blog.blurhash}
                width={"100%"}
                height={"100%"}
                resolutionX={32}
                resolutionY={32}
                punch={1}
                />
            </div>
            <img 
              style={{display: isImageLoaded === true ? "block" : "none"}} 
              className='blog-thumbnail' 
              src={blog.thumbnail} 
              alt="thumbnail" 
              onLoad={handleImageLoad}
            />
          </div>

          <h1 className="blog-title"> "{ blog.blog_title }" </h1>
          <div className="blog-date-and-minute"> {blog.date} • {blog.min} {t('read')} </div>
      </NavLink>
        </div>
    )
  }).reverse();
  
  return (
    <div id="blog" className="blog-container">
      <HelmetComp 
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        canonicalUrl={canonicalUrl}
        ogType={ogType}
        ogImage={ogImage}
        ogImageAlt={ogImageAlt}
      />
      {/* <h1 className='blog-head'> { t('short_articles') } </h1> */}
      { blogEl }
    </div>
  )
}

export default Blog