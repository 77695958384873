import React, { useState } from 'react'

const BackToTop = () => {
    const [backToTop, setBackToTop] = useState(false);
   
    const handleToTopClick = () => {
        window.scrollTo({
            top: 0
        })
    }
    const scrollBack = () => {
        if(window.scrollY >= 350) {
            setBackToTop(true)
        } else {
            setBackToTop(false)
        }
      }
      window.addEventListener("scroll", scrollBack)

  return (
    <div onClick={handleToTopClick} className={backToTop ? "back-to-top active": "back-to-top"}>
        <div className="arrow up"></div>
    </div>
  )
}

export default BackToTop