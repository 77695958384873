import { createBrowserRouter } from "react-router-dom";

// --custom components--
import App from "./App";
// --pages--
import PageNotFound from "./pages/PageNotFound";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Class from "./pages/Class";
import About from "./pages/About";
import BlogPosts from "./pages/BlogPosts";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: "",
                element: <Home />
            },
            {
                path: "blog",
                element: <Blog />,
            },
            {
                path: "blog/:id",
                element: <BlogPosts />
            },
            {
                path: "class",
                element: <Class />
            },
            {
                path: "about",
                element: <About />
            }
        ]
    }
])