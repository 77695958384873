import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import CacheBuster from 'react-cache-buster';
import PackageFile from '../package.json';
// --custom components--
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import BurgerMenu from './components/BurgerMenu';
import BackToTop from "./components/BackToTop";
import Loading from "./components/Loading";

function App() {
  const isProduction = process.env.NODE_ENV === 'production';

  const { version } = PackageFile;

  return ( 
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="App">
        <NavBar/>
        <BurgerMenu/>
        <Outlet />
        <Footer/>
        <BackToTop />
        <ScrollRestoration />
      </div>

    </CacheBuster>
  );
}

export default App;
