import React from 'react'
import { useTranslation } from 'react-i18next'
// --functional components--
import { NavLink } from 'react-router-dom'
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import BurgerMenu from '../components/BurgerMenu';
import HelmetComp from '../components/HelmetComp';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className='page-not-found-cont'>
      <HelmetComp 
        metaTitle={"404"}
      />
      <NavBar />
      <BurgerMenu />
        <div className="page-not-found">
          <h1>404!</h1>
          <p className='page-not-found-desc'>{t('page_not_found')}</p>

          <NavLink className="page-not-found-button" to={"/"}> {t('home')} </NavLink>
        </div>
      <Footer />
    </div>
  )
}

export default PageNotFound