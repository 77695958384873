import React, { useRef } from 'react'
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
// --functional components--
import Register from '../components/Register';
import HelmetComp from '../components/HelmetComp';
// --images--
import YoutubeIcon from "../assets/icon/youtube.png"
import { NavLink } from 'react-router-dom';
import BlogIcon from '../assets/icon/blog-white.png'
import OgLogo from '../assets/og-images/og-logo.png'

function Home() {
  const { t } = useTranslation();
  const blogs = t('blog_datas', {returnObjects: true})
  const currentLang = cookies.get('i18next');
  const registerRef = useRef(null);

  const handleScroll = () => {
    registerRef.current.scrollIntoView();
  }

  // --meta tags--
  const metaTitle = "እውነትን በፍቃድ | Truth By Will"
  const metaDescription = "This is a place through which we convey teachings from truth to human beings with humility and love."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogType = "website"
  const ogImage = OgLogo;
  const ogImageAlt = "Truth By Will"
  return (
    <main>
      {/*ESSENTIAL THINGS SECTION */}
      <section id="essential" className="essential-container">
      <HelmetComp 
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          canonicalUrl={canonicalUrl}
          ogType={ogType}
          ogImage={ogImage}
          ogImageAlt={ogImageAlt}
        />
        <div className="essential-content">
          <div className="welcome-text-cont">
             <span className="welcome-text"> {t('truth_by_will')} </span>
          </div>

          <h1 className='essential-header'>{t('essential_header')}</h1>
          <p className='essential-paragraph'> {t('essential_paragraph')} </p>
          
          <button className="register-btn" onClick={handleScroll}> 
              {t('register_now')} 
          </button>
        </div>
      </section>

      {/* LATEST VIDEOS SECTION */}
      <section className="latest-video-container">
        <div className="latest-video-header">
          {t('video_header')}
        </div>

        <iframe 
          className="youtube-iframe"
          src="https://www.youtube.com/embed/?listType=playlist&list=UUFpXCQ1DeMRJk6rAZadtq8g" 
          loading="lazy"
          title="YouTube video player"  
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen
        >
        </iframe>
      </section>

      {/*SECTION BLOG SECTION*/}
      <section className="home--blog-container">
        <div className="home--blog-content">
          <p className="home--blog-header"> {t('blog_header')} </p>
         
          {currentLang === 'en' && <p className="eng-version-not-yet">Sorry, The English version isn't available at the moment.</p>}

          <p className="home--blog-title"> "{ blogs[blogs.length - 1].blog_title }" </p>

          <p className="home--blog-description"> { blogs[blogs.length - 1].blog_description }</p> 

          <NavLink 
            to={`blog/${blogs[blogs.length - 1].path}`}
            className="home--blog-link"
          >
            <p className="home--blog-read-more"> {t('read_more')} </p> <span className="read-more-arrow">→</span>
          </NavLink>

          <NavLink 
            to={`blog/${blogs[blogs.length - 1].path}`}
            className='blog-navlink'
          >
            <img
              className='home-blog-thumbnail' 
              src={blogs[blogs.length - 1].thumbnail} 
              alt={blogs[blogs.length - 1].blog_title} 
              />
          </NavLink>

          <div className="date-and-minute"> {blogs[blogs.length - 1].date} • {blogs[blogs.length - 1].min} {t('read')} </div>
       
        <a 
          href={blogs[blogs.length - 1].video_link} 
          className="video-link" 
          target="_blank" rel="noopener noreferrer"
        >
          <img 
              src={YoutubeIcon} 
              alt="youtube" 
              width={20} 
              height={20}
            />
            <span className='video-link-text'> {`${t('watch')} "${blogs[blogs.length - 1].blog_title}" ${t('on_youtube')}`} </span>
        </a>

        <NavLink to="/blog" className='more-blog'>
          <img width= "15" height="15" src={BlogIcon} alt="blogs" /> 
          <span className='more-blog-text'> {t('read_more_blog')} </span> 
        </NavLink>

        </div>
      </section>

      {/* FORM AND MAP SECTION  */}
      <section ref={registerRef}>
        <Register classNames={"home-reg-form"}/>

      </section>
    </main>
  )
}

export default Home