import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import i18n from 'i18next';
import {initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
// --styles--
import './index.css';
// --custom components--
import { router } from './router';
import Loading from './components/Loading';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedlngs: ['en', 'am'],
    fallbackLng: "en",
    detection: {
        order: ['cookie', 'localStorage','path' , 'htmlTag', 'subdomain'],
        caches: ['cookie'],
    },
    backend: {
        loadPath: '/assets/locales/{{lng}}/translation.json'
    }
  });

// const loading = (
//   <div className="loading">
//     <div className="loading-ring"></div>
//   </div>
// )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Loading />}>
    <HelmetProvider>
      <RouterProvider router={router}/>
    </HelmetProvider>
  </Suspense>
);
