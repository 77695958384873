import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Blurhash } from "react-blurhash";
// --css--
import '../styles/class.css'
// --functional components--
import Register from '../components/Register';
import HelmetComp from '../components/HelmetComp';
// --images--
import TglBuilding from '../assets/image/tgl-buil.jpg'
import ClassRoom from '../assets/image/our-classroom.jpg'
import OgLogo from '../assets/og-images/og-logo.png'

function Class() {
  const { t } = useTranslation();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  }

  // --meta tags--
  const metaTitle = "Class | Truth By Will"
  const metaDescription = "You can attend our introduction class in person for free at our convenient classroom every sunday from 10:30 AM - 12:00 PM that lasts for an hour and a half."
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogType = "website"
  const ogImage = OgLogo;
  const ogImageAlt = "Class"

  return (
    <div id="class" className="class-container">
      <HelmetComp 
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        canonicalUrl={canonicalUrl}
        ogType={ogType}
        ogImage={ogImage}
        ogImageAlt={ogImageAlt}
      />
      <div className="classroom-container">
        <div className="classroom-img-cont">
          <div className="classroom-blurhash">
            <div className="blurhash" style={{display: isImageLoaded === false ? "inline" : "none"}}>
              <Blurhash 
                hash="LEGuwP~pIpMxx]?b8_xu00M_%2of"
                width={"100%"}
                height={"100%"}
                resolutionX={32}
                resolutionY={32}
                punch={1}
                />
            </div>
            <img 
              style={{display: isImageLoaded === true ? "block" : "none"}}
              className="classroom-img" 
              src={ClassRoom} 
              alt="classroom" 
              onLoad={handleImageLoad}
            />
          </div>
          <p className="classroom-label"> {t('truth_by_wills')} {t('classroom')}</p>
        </div>

        <p className="attend-text">
          {t('attend_text')} 
          <br />
          <br />
          {t('attend_for_more_info')}
        </p>
      </div>

      <div className="tgl-reg-cont">
        <Register classNames={"class-reg-form"}/>

        <div className="tgl-buil">
          <p className="tgl-buil-label"> {t('tgl_label')} </p>
          <div className="tgl-blurhash-cont">
            <div className="tgl-blurhash" style={{display: isImageLoaded === false ? "inline" : "none"}}>
              <Blurhash 
                hash="LaFsABo}RPjFyZkWoJafyYo#jZWB"
                width={"100%"}
                height={"100%"}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
            <img
              style={{display: isImageLoaded === true ? "block" : "none"}} 
              className="tgl-buil-img" 
              src={TglBuilding} 
              alt="TGL Building" 
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Class