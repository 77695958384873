import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
// import { Blurhash } from 'react-blurhash'
//--components--
import HelmetComp from '../components/HelmetComp'
//--images--
import YoutubeIcon from "../assets/icon/youtube.png"
import BlogIcon from '../assets/icon/blog-white.png'
// --og-images--
// import Unplaying from '../assets/og-images/unplaying.png'
// import BenefitAndLife from '../assets/og-images/benefit-and-life.png'

const BlogPosts = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  // const [isImageLoaded, setIsImageLoaded] = useState(false)

  // const handleImageLoad = () => {
  //   setIsImageLoaded(true)
  // }

  const blogs = t('blog_datas', {returnObjects: true})
  const blogPost = blogs.find(blog => blog.path === id)

  // --meta tags--
  const metaTitle = blogPost.blog_title
  const metaDescription = blogPost.blog_seo_description
  const baseUrl = window.location.origin;
  const currentPath = window.location.pathname
  const canonicalUrl = `${baseUrl}${currentPath}`
  const ogType = "article"
  const ogImage = blogPost.og_image;
  const ogImageAlt = blogPost.blog_title;

  return (
    <div key={blogPost.blog_title} className="blog-post-content">
      <HelmetComp 
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        canonicalUrl={canonicalUrl}
        ogType={ogType}
        ogImage={ogImage}
        ogImageAlt={ogImageAlt}
      />

      <h1 className="blog-post-title"> "{ blogPost.blog_title }" </h1>
      <div className="blog-post-date-and-minute"> {blogPost.date} • {blogPost.min} {t('read')} </div>

      {/* <div className="blog-post-blurhash">
        <div className="blurhash-cont" style={{display: isImageLoaded === false ? "inline" : "none"}}>
          <Blurhash 
            hash={blogPost.blurhash}
            width={"100%"}
            height={"100%"}
            resolutionX={32}
            resolutionY={32}
            punch={1}
            />
        </div>  
        
        </div>*/}

      <img
        // style={{display: isImageLoaded === true ? "block" : "none"}} 
        className='blog-post-thumbnail' 
        src={blogPost.thumbnail} 
        alt="thumbnail" 
        // onLoad={handleImageLoad}
      />

      <p className="blog-post-description"> {blogPost.blog_content} </p>
      
      <a 
        href={blogPost.video_link} 
        className="video-link" 
        target="_blank" rel="noopener noreferrer"
      >
        <img 
            src={YoutubeIcon} 
            alt="youtube" 
            width={20} 
            height={20}
          />
          <span className='video-link-text'> {`${t('watch')} "${blogPost.blog_title}" ${t('on_youtube')}`} </span>
      </a>

      <NavLink to="/blog" className='more-blog'>
        <img width= "15" height="15" src={BlogIcon} alt="blogs" /> 
        <span className='more-blog-text'> {t('read_more_blog')} </span> 
      </NavLink>
  </div>
  )
}

export default BlogPosts